<template>    
    <div>
        <b-row>
            <b-col md="12">
                <h2 class="mb-2" style="padding-bottom: 5px; padding-top: 9px;">My Profile</h2>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <b-tabs card class="caption-add table-custom">
                    <b-tab title="Bacis Info" active class="overview-tab products-table">
                        <validation-observer
                          ref="profile_list"
                          #default="{invalid}" >
                            <b-form @submit.prevent class="profile-frm">
                                <div class="text-right mb-2">

                                    <b-button type="button" variant="primary" class="btn-df" @click="editAction" v-if="!isEditing" style="color: white !important">Edit</b-button>

                                    <b-button type="button" variant="basic" class="btn-df size-18 black" v-else-if="isEditing" @click="cancelAction" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px ">Cancel</b-button>

                                    <b-button type="button" variant="primary" class="btn-df size-18 black" @click="save" v-if="isEditing">
                                        Save
                                    </b-button>

                                </div>
                                <div style="border-top: 1px solid #000000B2;" class="pb-2 mb-10"></div>
                                <div class="frm-basic size-16 black-child d-block">              
                                      <b-row style="width: 100%; margin: 0;">   
                                          <b-col lg="6" class="px-0">             
                                              <b-form-group label="ID" label-cols-lg="3">
                                                    <b-form-input
                                                      v-model="form.id"
                                                      placeholder="Enter ID"
                                                      disabled
                                                    ></b-form-input>
                                              </b-form-group>
                                          </b-col>
                                          <b-col lg="6" class="px-0">             
                                              <b-form-group label="Username" label-cols-lg="3">
                                                    <b-form-input
                                                      v-model="form.username"
                                                      placeholder="Username"
                                                      :id="disableField"
                                                      :disabled = "isDisabled"
                                                      :formatter="length50"
                                                    ></b-form-input>
                                              </b-form-group>
                                          </b-col>
                                          <b-col lg="6" class="px-0" v-if="is_show">             
                                              <b-form-group label="Password" label-cols-lg="3">

                                                    <b-button type="button" variant="primary" class="btn-200 mr-10" @click="changePassAction" style="max-width: 100%; ">Change Password</b-button>

                                              </b-form-group>
                                          </b-col>
                                          <b-col lg="12" class="px-0">             
                                            <b-form-group label="Staff Name" label-cols-lg="2" class="star-required field-12-width">     
                                              <validation-provider
                                                #default="{ errors }"
                                                name="Staff Name"
                                                rules="required"                      
                                              >                        
                                                  <b-form-input
                                                      v-model="form.full_name"
                                                      placeholder="Name"
                                                      :id="disableField"
                                                      :disabled = "isDisabled"
                                                      :formatter="length50"
                                                  ></b-form-input>
                                                  <small class="text-danger">{{ errors[0] }}</small>
                                              </validation-provider>
                                            </b-form-group>
                                          </b-col>
                                          <b-col lg="12" class="px-0">             
                                            <b-form-group label="Email" label-cols-lg="2" class="star-required field-12-width">     
                                              <validation-provider
                                                #default="{ errors }"
                                                name="Email"
                                                rules="required|email"                      
                                              >                        
                                                  <b-form-input
                                                      type = "email"
                                                      v-model="form.email"
                                                      placeholder="Email"
                                                      :id="disableField"
                                                      :disabled = "isDisabled"
                                                  ></b-form-input>
                                                  <small class="text-danger">{{ errors[0] }}</small>
                                              </validation-provider>
                                            </b-form-group>
                                          </b-col>
                                          <b-col lg="6" class="px-0">             
                                              <b-form-group label="Role" label-cols-lg="3">
                                                  <v-select        
                                                    v-model="selectedRole"  
                                                    label="name"
                                                    :options="selectRole"  
                                                    placeholder="Select Role"
                                                    disabled
                                                    id="disabled-field"
                                                  />
                                              </b-form-group>
                                          </b-col> 
                                          <b-col lg="6" class="px-0">             
                                              <b-form-group label="Status" label-cols-lg="3">
                                                  <v-select   
                                                    v-model="selectedStatus"  
                                                    label="title"
                                                    :options="selectStatus"  
                                                    placeholder="Select Status"
                                                    id="disabled-field"
                                                    disabled
                                                  />
                                              </b-form-group>
                                          </b-col>                
                                      </b-row>
                                </div>
                                <b-modal id="modalProfile" ref="change_modal" title="Change Password" hide-header-close ok-title="Save">
                                    <b-row>
                                        <b-col lg="12" class="px-0">
                                            <b-form-group label="Old Password" label-cols-xl="4"
                                              label-cols-md="4" label-class="text-lg-right bold">

                                              <validation-provider
                                                #default="{ errors }"
                                                rules="required"
                                                name="Old Password"
                                                vid="oldpassword"
                                              >
                                                  <b-form-input
                                                    placeholder="Old Password"
                                                    v-model="formPW.old_password"
                                                    type="password"
                                                    required
                                                  ></b-form-input>
                                                  <small class="text-danger">{{ errors[0] }}</small>
                                              </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col lg="12" class="px-0">
                                            <b-form-group label="New Password" label-cols-xl="4"
                                              label-cols-md="4" label-class="text-lg-right bold">
                                              <validation-provider
                                                #default="{ errors }"
                                                rules="required|min:12"
                                                name="New Password"
                                                vid="password"
                                              >
                                                <b-form-input
                                                  placeholder="New Password"
                                                  v-model="formPW.new_password"
                                                  type="password"
                                                ></b-form-input>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                                
                                              </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col lg="12" class="px-0">
                                            <b-form-group label="Confirm New Password" label-cols-xl="4" label-cols-md="4" label-class="text-lg-right bold">
                                                <validation-provider
                                                  #default="{ errors }"
                                                  rules="required|confirmed:password"
                                                  name="Confirm New Password"
                                                >
                                                  <b-form-input
                                                    id="input-confirmPass"
                                                    placeholder="Confirm New Password"
                                                    v-model="formPW.confirm_new_password"
                                                    type="password"
                                                  ></b-form-input>
                                                  <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col>
                                            <div class="size-12 bold">
                                              At least 12 characters and contain characters from at least two (2) of the following four (4) categories:
                                              <ul>
                                                  <li>Upper case (A through Z)</li>
                                                  <li>Lower case (a through z)</li>
                                                  <li>Digits (0-9)</li>
                                                  <li>Special characters (!,$,#,%, etc)</li>
                                              </ul>
                                            </div>    
                                        </b-col>
                                    </b-row>     
                                    <template #modal-footer="{ cancel }">
                                        <b-button size="sm" variant="static" class="btn-df size-18 black" @click="cancelPass" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) ">
                                          Cancel
                                        </b-button>
                                        <b-button size="sm" variant="primary" class="btn-df size-18 black" @click="changPass">
                                          Save
                                        </b-button>                        
                                    </template>        
                                </b-modal>
                            </b-form>  
                        </validation-observer>   
                    </b-tab>
                </b-tabs>
            </b-col>
        </b-row>   
    </div>
</template>

<script>
    import flatPickr from 'vue-flatpickr-component'
    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import { getHomeRouteForLoggedInUser } from '@/auth/utils'
    extend('min', {
    ...min,
    message: 'At least 12 characters and contain characters from at least two (2) of the following four (4) categories: Upper case (A through Z), Lower case (a through z), Digits (0-9), Special characters (!,$,#,%, etc)'
	});
    export default {        
        components: {
          flatPickr,
          vSelect,
          ValidationProvider,
          ValidationObserver,
        },
        mixins: [togglePasswordVisibility],
        data() {
          return {                
                form: {},
                formPW: {
                  old_password: null,
                  new_password: null,
                  confirm_new_password: null
                },
                salutation: [
                  { title: 'Mr', value: 0}, 
                  { title: 'Mrs', value: 1 },
                  { title: 'Miss', value: 2 },
                  { title: 'Ms', value: 3 },
                  { title: 'Sir', value: 4 },
                  { title: 'Dr', value: 5 },
                ],
                gender: [
                  { title: 'Male', value: 0 },
                  { title: 'Female', value: 1 }
                ],
                country: [],
                isEditing: false,
                isDisabled: true,
                maxBirthday: null,
                disableField: 'disabled-field',
                is_show: false,

                selectStatus: [
                  { title: 'Inactive', value: 0 },
                  { title: 'Active', value: 1 },
                ],
                selectedStatus: {
                  title: null, 
                  value: null
                },
                selectedRole: {},
                selectRole: [],
                userData: JSON.parse(localStorage.getItem('userData')),
          }
        },
        created(){
            this.userProfile();        
            this.fetchCountry();
        },
        methods: {
          length50(e){
              return String(e).substring(0,50)
          },
          userProfile(){
              this.$store.dispatch('auth/detailStaff', {
                  id: this.userData.id
              })
              .then(response => {
                  this.form = Object.assign(response.data.data, response.data.data.infouser)

                  if(this.form.active == 0){
                      this.selectedStatus.title = "Inactive"
                      this.selectedStatus.value = 0
                  }
                  if(this.form.active == 1){
                      this.selectedStatus.title = "Active"
                      this.selectedStatus.value = 1
                  }
                  if(this.form.role){
                    this.selectRole = this.form.role
                    this.selectedRole = this.form.role.map(item => item )
                  }
                  // console.log('Profile: ', this.form)

              })
              .catch((error) => {
                console.log(error)
              })
          },
          fetchCountry(){
              this.$store
              .dispatch('country/fetchCountry', {
                  limit: '',
              })
              .then(response => {
                this.country = response.data.data.data
              })
              .catch((error) => {
                console.log(error)
              })
          }, 
          cancelAction(){
              this.isEditing = false
              this.isDisabled = true
              this.disableField= 'disabled-field'
              this.is_show = false
              this.userProfile(); 
          },
          save() {
            this.$refs.profile_list.validate().then(success => {
              if (success) {
                let request = {
                  full_name: this.form.full_name,
                  username: this.form.username,
                  email: this.form.email,
                }
                this.$store.dispatch('auth/saveProfileStaff', request)
                .then(response => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: this.form.full_name + ' Updated!',
                          variant: 'success',
                        },
                      },
                      {
                        position: 'top-center'
                      }
                    )
                    this.isEditing = false
                    this.isDisabled = true
                    this.disableField= 'disabled-field'
                    this.is_show = false
                    this.refreshTokenLocalStogre()
                })
                .catch((error) => {
                    console.log(error)
                   this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: error.response.data.message,
                          variant: 'danger',
                        },
                    },
                    {
                      position: 'top-center'
                    })
                })
              }
            })
          },
          refreshTokenLocalStogre(){
              this.$store.dispatch('auth/refreshToken')
              .then(response => {
                window.location.reload()
              })
              .catch((error) => {   
                  console.log('error: ', error);
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: error.response.data.message,
                        variant: 'danger',
                      },
                  },
                  {
                    position: 'top-center'
                  })
              })
          },
          cancelPass(){
              this.$refs['change_modal'].hide() 
          },
          changePassAction(){
              this.$refs['change_modal'].show()
              this.formPW.old_password = null
              this.formPW.new_password = null
              this.formPW.confirm_new_password = null  
          },
          changPass(){
            this.$refs.profile_list.validate().then(success => {
              if (success) {
                this.$store.dispatch('auth/changPass', this.formPW).then(response => {
                    this.$refs['change_modal'].hide()  
                    this.$toast({
                          component: ToastificationContent,
                          props: {
                              title: 'Password changed!',
                              variant: 'success',
                          },
                    },
                    {
                          position: 'top-center'
                    })               
                    this.isEditing = false
                    this.isDisabled = true
                    this.disableField= 'disabled-field'
                    this.is_show = false
                })
                .catch((error) => {
                    console.log(error.response.data)   
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: error.response.data.message,
                          variant: 'danger',
                      },
                    },
                    {
                      position: 'top-center'
                    })             
                })
              }
            })
          },
          editAction(){
              this.isEditing = true
              this.isDisabled = false
              this.disableField = ''
              this.is_show = true
          }
        }
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    @import "@/assets/scss/_ite_tab_table.scss";

    #modalProfile .modal-dialog .modal-header{
        justify-content: center;
        padding-top: 0;
    }
    #modalProfile .modal-dialog .modal-header h5{
        font-size: 24px;
        color: black;
    }
    #modalProfile .modal-dialog .modal-footer{
        justify-content: center;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        border: 0; 
    }
    #modalProfile .modal-dialog .modal-content{
      padding: 50px 30px; 
    }    
    #modalProfile .modal-footer button{
        border-radius: 5px;
        border: 0;
    }
    #modalProfile .modal-footer button.btn-secondary{
        box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
        border-radius: 5px;
        border: 0;
        color: #442E2E !important;
        background-color: #fff !important;
    }
    #modalProfile .modal-footer button.btn-primary{
        color: #fff !important
    }
    .Vue-Toastification__container.top-center .toastification *{
       text-align: center !important;
    }
    @media only screen and (min-width: 1600px){
      #modalProfile .modal-dialog{
          margin-top: 127px; 
      }
    }
    @media only screen and (min-width: 1440px){
        #modalProfile .modal-dialog .col-form-label.col-xl-4 {
            flex: 0 0 33%;
            max-width: 33%;
        }
    }
    @media only screen and (max-width: 1400px){
      #modalProfile .modal-dialog .modal-content {
          padding: 30px;
      }
    }
    @media only screen and (min-width: 992px) {
        .frm-basic div.d-lg-flex > div{
          width: 50%; 
        }
    }
    @media only screen and (min-width: 635px){
        #modalProfile .modal-dialog {
            max-width: 635px;
        }
    }  
</style>